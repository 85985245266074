var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"color":_vm.isFiltered ? 'primary': 'default',"icon":"","plain":""},on:{"click":function () {
        _vm.showFilters = !_vm.showFilters
      }},model:{value:(_vm.showFilters),callback:function ($$v) {_vm.showFilters=$$v},expression:"showFilters"}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFilter))])],1),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"icon":""},on:{"click":function () { return _vm.tryFetchActions(); }}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1),_c('v-progress-circular',{attrs:{"value":_vm.progress,"size":50,"rotate":270,"indeterminate":_vm.loading,"color":"accent"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._v(_vm._s(_vm.countdown))])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":0,"server-items-length":_vm.total,"item-key":"id","loading-text":"Loading actions ...","show-expand":"","loading":_vm.loading,"items":_vm.actions,"headers":_vm.actionHeaders,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-options': _vm.pages,
        'show-first-last-page': true,
        'show-current-page': true,
      }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"pa-0 ma-0",attrs:{"label":"Status","placeholder":"Select status","hide-details":"","clearable":"","items":_vm.actionStatuses},model:{value:(_vm.tableOptions.status),callback:function ($$v) {_vm.$set(_vm.tableOptions, "status", $$v)},expression:"tableOptions.status"}})],1)],1)],1)]},proxy:true},{key:"header.data-table-select",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomSelectRows',_vm._g({attrs:{"value":props,"page":_vm.tableOptions.itemsPerPage,"total":_vm.total}},on))]}},{key:"item.type",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"role":"button","to":{name: 'discordActionDetails', params: {id:item.id}}}},[_vm._v(" "+_vm._s(item.type)+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiOpenInNew)+" ")])],1)]}},{key:"item.count_by_status",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('div',{staticClass:"statuses"},_vm._l((value),function(count,key){return _c('v-tooltip',{key:("" + item + key),attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(count),expression:"count"}],staticClass:"ml-1 status text-center",class:_vm.statusColors[key],style:({
                  width:((count / _vm.totalStatus(value) * 100) + "%")
                })},'div',attrs,false),on),[_vm._v(" "+_vm._s(count)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(key)+": "+_vm._s(count))])])}),1)]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.createdTime(item.created_at))+" ")]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length-2}},[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_vm._v(" Pending: ")]),_c('v-col',[_vm._v(" "+_vm._s(item.count_by_status.pending))]),_c('v-col',{attrs:{"md":"2"}},[_vm._v(" In progress: ")]),_c('v-col',[_vm._v(" "+_vm._s(item.count_by_status.in_progress))])],1),_c('v-row'),_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_vm._v(" Success: ")]),_c('v-col',[_vm._v(" "+_vm._s(item.count_by_status.success))]),_c('v-col',{attrs:{"md":"2"}},[_vm._v(" Skip: ")]),_c('v-col',[_vm._v(" "+_vm._s(item.count_by_status.skip))])],1),_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_vm._v(" Banned: ")]),_c('v-col',[_vm._v(" "+_vm._s(item.count_by_status.banned))]),_c('v-col',{attrs:{"md":"2"}},[_vm._v(" Locked: ")]),_c('v-col',[_vm._v(" "+_vm._s(item.count_by_status.locked))])],1),_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_vm._v(" Error Captcha: ")]),_c('v-col',[_vm._v(" "+_vm._s(item.count_by_status.error_captcha))]),_c('v-col',{attrs:{"md":"2"}},[_vm._v(" Error Skip Captcha: ")]),_c('v-col',[_vm._v(" "+_vm._s(item.count_by_status.error_skip_captcha))])],1),_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_vm._v(" Proxy Error: ")]),_c('v-col',[_vm._v(" "+_vm._s(item.count_by_status.proxy_error))])],1)],1)]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-layout',{attrs:{"d-flex":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(['stopped','stoped', 'done'].includes(item.status)),expression:"['stopped','stoped', 'done'].includes(item.status)"}],attrs:{"icon":"","color":"primary"},on:{"click":function () { return _vm.openRetryActionModal({id:item.id,account_delay:item.account_delay,
                                                    batch_delay: item.batch_delay,
                                                    batch_size: item.batch_size,}); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiReplay))])],1)]}}],null,true)},[_c('span',[_vm._v("Retry")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(['in_progress', 'pending'].includes(item.status)),expression:"['in_progress', 'pending'].includes(item.status)"}],attrs:{"icon":"","color":"primary"},on:{"click":function () { return _vm.tryStopAction(item.id); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiStop))])],1)]}}],null,true)},[_c('span',[_vm._v("Stop")])])],1)]}},{key:"footer",fn:function(ref){
                                                    var props = ref.props;
                                                    var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}]),model:{value:(_vm.selectedActions),callback:function ($$v) {_vm.selectedActions=$$v},expression:"selectedActions"}})],1),_c('RetryActionModal',{ref:"retryActionModal",on:{"updated":_vm.commitEditAction}}),_c('ConfirmDialog',{ref:"deleteActionConfirm",attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var agree = ref.agree;
                                                    var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Delete action")]),_c('v-card-text',[_vm._v(" Do you really want to delete action ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":agree}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))]),_vm._v(" Delete ")],1),_c('v-btn',{on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }