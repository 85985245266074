<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-model="showFilters"
        :color="isFiltered ? 'primary': 'default'"
        icon
        plain
        @click="() =>{
          showFilters = !showFilters
        }"
      >
        <v-icon>{{ icons.mdiFilter }}</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        v-show="!loading"
        icon
        @click="() => tryFetchActions()"
      >
        <v-icon>
          {{ icons.mdiRefresh }}
        </v-icon>
      </v-btn>
      <v-progress-circular
        :value="progress"
        :size="50"
        :rotate="270"
        :indeterminate="loading"
        color="accent"
      >
        <span v-show="!loading">{{ countdown }}</span>
      </v-progress-circular>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectedActions"
        hide-default-footer
        :mobile-breakpoint="0"
        :server-items-length="total"
        item-key="id"
        loading-text="Loading actions ..."
        show-expand
        :loading="loading"
        :items="actions"
        :headers="actionHeaders"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <template
          v-slot:top
        >
          <v-layout
            v-show="showFilters"
            class="pa-4"
          >
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="tableOptions.status"
                  label="Status"
                  placeholder="Select status"
                  hide-details
                  clearable
                  :items="actionStatuses"
                  class="pa-0 ma-0"
                ></v-select>
              </v-col>
            </v-row>
          </v-layout>
        </template>
        <!-- SELECT ROW-->
        <template v-slot:header.data-table-select="{ props, on }">
          <CustomSelectRows
            :value="props"
            :page="tableOptions.itemsPerPage"
            :total="total"
            v-on="on"
          />
        </template>
        <template v-slot:item.type="{value, item}">
          <v-chip
            class="ma-2"
            role="button"
            :to="{name: 'discordActionDetails', params: {id:item.id}}"
          >
            {{ item.type }}
            <v-icon
              small
              class="ml-1"
              color="primary"
            >
              {{ icons.mdiOpenInNew }}
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:item.count_by_status="{value, item}">
          <div class="statuses">
            <v-tooltip
              v-for="(count, key) in value"
              :key="`${item}${key}`"
              top
            >
              <template v-slot:activator="{on, attrs}">
                <div
                  v-show="count"
                  v-bind="attrs"
                  :class="statusColors[key]"
                  class="ml-1 status text-center"
                  :style="{
                    width:`${count / totalStatus(value) * 100}%`
                  }"
                  v-on="on"
                >
                  {{ count }}
                </div>
              </template>
              <span>{{ key }}: {{ count }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.created_at="{item}">
          {{ createdTime(item.created_at) }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="2"></td>
          <td
            class="pa-4"
            :colspan="headers.length-2"
          >
            <v-row>
              <v-col md="2">
                Pending:
              </v-col>
              <v-col> {{ item.count_by_status.pending }}</v-col>
              <v-col md="2">
                In progress:
              </v-col>
              <v-col> {{ item.count_by_status.in_progress }}</v-col>
            </v-row>
            <v-row>
            </v-row>
            <v-row>
              <v-col md="2">
                Success:
              </v-col>
              <v-col> {{ item.count_by_status.success }}</v-col>
              <v-col md="2">
                Skip:
              </v-col>
              <v-col> {{ item.count_by_status.skip }}</v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                Banned:
              </v-col>
              <v-col> {{ item.count_by_status.banned }}</v-col>

              <v-col md="2">
                Locked:
              </v-col>
              <v-col> {{ item.count_by_status.locked }}</v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                Error Captcha:
              </v-col>
              <v-col> {{ item.count_by_status.error_captcha }}</v-col>
              <v-col md="2">
                Error Skip Captcha:
              </v-col>
              <v-col> {{ item.count_by_status.error_skip_captcha }}</v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                Proxy Error:
              </v-col>
              <v-col> {{ item.count_by_status.proxy_error }}</v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item.action="{ item }">
          <v-layout d-flex>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="['stopped','stoped', 'done'].includes(item.status)"
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click="() => openRetryActionModal({id:item.id,account_delay:item.account_delay,
                                                      batch_delay: item.batch_delay,
                                                      batch_size: item.batch_size,})"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiReplay }}</v-icon>
                </v-btn>
              </template>
              <span>Retry</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="['in_progress', 'pending'].includes(item.status)"
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click="() => tryStopAction(item.id)"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiStop }}</v-icon>
                </v-btn>
              </template>
              <span>Stop</span>
            </v-tooltip>
          </v-layout>
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <RetryActionModal
      ref="retryActionModal"
      @updated="commitEditAction"
    />
    <!-- <ActionEditModal
      ref="editActionModal"
      @update="tryUpdateAction"
    /> -->

    <ConfirmDialog
      ref="deleteActionConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete action</v-card-title>
          <v-card-text>
            Do you really want to delete action
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import {
  mdiRefresh,
  mdiDeleteForever,
  mdiCheck,
  mdiCancel,
  mdiCheckCircleOutline,
  mdiLockOutline,
  mdiDotsVertical,
  mdiDotsHorizontal,
  mdiFilter,
  mdiPencil,
  mdiMagnify,
  mdiShieldOffOutline,
  mdiCellphoneKey,
  mdiEmailCheckOutline,
  mdiCloseCircleOutline,
  mdiCloseOctagonOutline,
  mdiClipboardTextOutline,
  mdiLinkVariantPlus,
  mdiGiftOpenOutline,
  mdiOpenInNew,
  mdiFormatListBulletedSquare,
  mdiReplay,
  mdiStop,
} from '@mdi/js'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import { statusColors, actionStatuses } from '@core/constants'
import DiscordApi from '@core/api/discord'
import _cloneDeep from 'lodash/cloneDeep'
import store from '@/store'
import discordStoreModule, { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'
import CustomSelectRows from '@/components/CustomSelectRows.vue'

import ConfirmDialog from '@/components/ConfirmDialog.vue'
import RetryActionModal from '@/components/discord/action/RetryActionModal.vue'
import TableFooter from '@/components/TableFooter.vue'

export default {
  components: {
    CustomSelectRows,
    ConfirmDialog,
    RetryActionModal,
    TableFooter,
  },
  data() {
    return {
      loading: true,
      intervalId: null,
      countdown: 60,
      types: [],
      actionStatuses,
      statusColors,
      tableOptions: {
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
        status: this.$route.query.status,
      },
      search: '',
      selectedActions: [],
      total: 50,
      isProgress: true,
      pages: [50, 100, 200],
      verificationIcons: {
        'phone verified': mdiCellphoneKey,
        'mail verified': mdiEmailCheckOutline,
        'non verified': mdiShieldOffOutline,
        null: mdiCloseOctagonOutline,
      },
      typeIcons: {
        referral: mdiLinkVariantPlus,
        giveaway: mdiGiftOpenOutline,
        'white list': mdiFormatListBulletedSquare,
      },
      showFilters: false,
      icons: {
        mdiOpenInNew,
        mdiRefresh,
        mdiDotsVertical,
        mdiDotsHorizontal,
        mdiFilter,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
        mdiLockOutline,
        mdiCheck,
        mdiCloseCircleOutline,
        mdiCheckCircleOutline,
        mdiClipboardTextOutline,
        mdiCancel,
        mdiReplay,
        mdiStop,
      },
      actionHeaders: [
        {
          text: 'Type',
          value: 'type',
          sortable: true,
          filterable: true,
          width: '104px',
        },
        {
          text: '',
          value: 'count_by_status',
          sortable: false,
          width: '100%',
        },
        {
          text: 'Status',
          value: 'status',
          width: '90px',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Created at',
          value: 'created_at',
          width: '120px',
          sortable: true,
          filterable: true,
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      actions(state) {
        return state[DISCORD_MODULE_NAME]?.action.actions
      },
    }),
    isFiltered() {
      return this.showFilters || this.checkFilterToggle()
    },
    mappedOptions() {
      const opts = this.$route.query

      return opts
    },
    progress() {
      return this.countdown * 1.6
    },
  },
  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status'])
        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchActions(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(DISCORD_MODULE_NAME)) {
      store.registerModule(DISCORD_MODULE_NAME, discordStoreModule)
    }
    this.tryFetchActions()
  },
  destroyed() {
    this.isProgress = false
    clearInterval(this.intervalId)
  },
  methods: {
    ...mapActions({
      fetchActions(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/fetchActions`, payload)
      },
      updateAction(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/updateAction`, payload)
      },
      removeAction(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/removeAction`, payload)
      },
    }),

    ...mapMutations({
      commitSetActions(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/SET_ACTIONS `, payload)
      },
      commitEditAction(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/EDIT_ACTION`, payload)
      },
      commitDeleteAction(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/REMOVE_ACTION`, payload)
      },
    }),

    totalStatus(statuses) {
      return Object.keys(statuses).reduce((total, key) => total + statuses[key], 0)
    },

    restartRefreshInterval() {
      this.countdown = 60
      clearInterval(this.intervalId)
      this.intervalId = setInterval(() => {
        this.countdown -= 1
        if (!this.countdown) {
          clearInterval(this.intervalId)
          this.tryFetchActions()
        }
      }, 1000)
    },

    async tryFetchActions(params = this.mappedOptions) {
      this.loading = true
      const { data } = await this.fetchActions(params)
      this.total = data.total
      this.loading = false
      if (this.isProgress) {
        this.restartRefreshInterval()
      }
    },

    async tryDeleteAction(id) {
      this.$refs.deleteActionConfirm.open().then(() => {
        this.commitEditAction({ id, loading: true })
        this.removeAction({ id }).then(() => {
          this.commitDeleteAction({ id })
        })
      })
    },

    async tryUpdateAction(value) {
      this.commitEditAction({ ...value, loading: true })
      const { data } = await this.updateAction({ action: value })
      this.commitEditAction(data)
    },

    async tryStopAction(id) {
      try {
        const { data } = await DiscordApi.Action.stopAction({ id })
        this.commitEditAction(data)
        this.$message.success('Action stopped')
      } catch (err) {
        this.$message.error(err)
      }
    },

    openActionEditModal(action) {
      this.$refs.editActionModal.open(action)
    },

    openRetryActionModal(action) {
      this.$refs.retryActionModal.open(action)
    },
    checkFilterToggle() {
      const filters = _cloneDeep(this.tableOptions)
      const tableKeys = ['search', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort']
      let filtersStatus = false
      Object.entries(filters).forEach(([key, value]) => {
        if (tableKeys.includes(key)) {
          delete filters[key]
        } else if (!!value !== false) {
          filtersStatus = true
        }
      })

      return filtersStatus
    },
    createdTime(created) {
      return created && created.replace('T', ' ').slice(0, created.length - 7)
    },
  },
}
</script>

<style lang="scss" scoped>
.statuses{
  display: flex;
  .status{
    min-width: fit-content;
    &:last-child{
      background: red;
    }
  }
}
</style>
